<template>
  <div class="page-view home">
    <div class="container">
      <div class="flex-wrapper">
        <div class="item">
          <h1 class="page-title">—Dashboard</h1>
          <h3 class="subtitle" v-if="activeCampaigns">Ciao, attualmente ci sono n° <span class="big">{{ isOldCashCarry ? activeCampaigns.length + 1 : activeCampaigns.length }}</span> campagne attive.</h3>
        </div>
        <div class="item large">

        </div>
      </div>
    </div>
    <section class="active-campaigns">
      <div class="container mx-auto px-4">
        <ul v-if="activeCampaigns || isOldCashCarry">
          <li v-for="(camp, index) in activeCampaigns" :key="index">
            <CampaignTeaser :camp="camp" />
          </li>
          <li v-if="isOldCashCarry">
            <article class="teaser campaign-teaser">
              <a href="https://ordini-unicomm.promotica.it/" target="_blank">
              <!-- <router-link :to="'/campaign/' + camp.id"> -->
                <h4>
                  C+C UNICOMM CATALOGO 2023-24
                  <v-icon name="fa-chevron-right" />
                </h4>
                <time>
                  Esci dal sito e vai al nuovo portale ›
                </time>
              </a>
            </article>
          </li>
        </ul>
        <h5 class="error" v-else>Non risultano campagne <b>attive</b> al momento.</h5>
      </div>
    </section>
    <div class="divider">
      <div class="container mx-auto px-4">
        <h3>
          Campagne <b>inattive</b>
        </h3>
      </div>
    </div>
    <section class="past-campaigns">
      <div class="container mx-auto px-4">
        <ul v-if="pastCampaigns">
          <li v-for="(camp, index) in pastCampaigns" :key="index">
            <CampaignTeaser :camp="camp" />
          </li>
        </ul>
        <h5 class="error" v-else>Non risultano campagne <b>inattive</b> al momento.</h5>
      </div>
    </section>
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout <b v-if="userData && userData.email">{{userData.email}}</b>
    </a>
  </div>
</template>

<script>
import CampaignTeaser from '@/components/CampaignTeaser.vue'
import campaigns from '@/json/mock/campaigns.json'
import moment from 'moment'
moment.locale('it')

export default {
  name: 'Home',
  components: {
    CampaignTeaser
  },
  data () {
    return {
      interval: null,
      loading: false,
      history: null,
      phoneVal: null,
      descVal: null,
      mailVal: null,
      stats: null,
      categories: [],
      filteredCats: [],
      testQuery: this.$route.query.test,
      validated: false,
      validated2: false,
      animatedList: [],
      campaigns
      // pastCampaigns: []
    }
  },
  computed: {
    extraData () {
      return this.$store.state.userData ? JSON.parse(this.$store.state.userData.extra_data) : false
    },
    isOldCashCarry () {
      if (
          this.extraData?.enabled_agreements_no &&
          this.extraData.enabled_agreements_no[0] &&
          this.extraData?.enabled_agreements_no.includes(1424)
      ) {
        return true
      }

      return false
    },
    userData () {
      return this.$store.state.userData
    },
    // activeCampaigns () {
    //   return this.$store.state.campaigns
    // }
    activeCampaigns () {
      if (this.$store.state.campaigns && this.$store.state.campaigns[0]) {
        this.$store.state.campaigns.filter((el) => {
          if (el.start_date && el.end_date) {
            const start = moment(el.start_date)
            const end = moment(el.end_date)
            const today = moment()
            return today.isBetween(start, end)
          }
        })
      }
      return null
    },
    pastCampaigns () {
      if (this.$store.state.campaigns && this.$store.state.campaigns[0]) {
        this.$store.state.campaigns.filter((el) => {
          if (el.start_date && el.end_date) {
            const start = moment(el.start_date)
            const end = moment(el.end_date)
            const today = moment()
            return !today.isBetween(start, end)
          }
        })
      }
      return null
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    }
  },
  mounted () {
    this.$router.push('/block')
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/home.scss";
  @import "@/assets/scss/teaser-campaign.scss";
</style>
